<template>
    <v-card flat tile>
        <v-card-title dark class="secondary white--text text-h5">
            Boutique
        </v-card-title>
        <v-snackbar v-model="showResult" :timeout="30000" top :color="color" tile>
            {{ result }}
        </v-snackbar>
        <v-card-text>
            <v-container>
                <!-- prod -->
                <stripe-buy-button buy-button-id="buy_btn_1NxQG0CScj5XfnbxaVRvNP1e"
                    publishable-key="pk_live_51NWbThCScj5Xfnbx27IrkpKT1DGRcA9b7uHAkneTcwkX8pKIQpLu7FM5SVgL29AFg6yEYOvQgb1sMkKPnwAOkUCN00ASlmlDU8" />
                <stripe-buy-button buy-button-id="buy_btn_1NxQIqCScj5Xfnbx7b9gqLHJ"
                    publishable-key="pk_live_51NWbThCScj5Xfnbx27IrkpKT1DGRcA9b7uHAkneTcwkX8pKIQpLu7FM5SVgL29AFg6yEYOvQgb1sMkKPnwAOkUCN00ASlmlDU8" />
                <stripe-buy-button buy-button-id="buy_btn_1NxQJRCScj5XfnbxBFHQNsVp"
                    publishable-key="pk_live_51NWbThCScj5Xfnbx27IrkpKT1DGRcA9b7uHAkneTcwkX8pKIQpLu7FM5SVgL29AFg6yEYOvQgb1sMkKPnwAOkUCN00ASlmlDU8" />
                <stripe-buy-button buy-button-id="buy_btn_1NxQHLCScj5Xfnbx2ctxryCu"
                    publishable-key="pk_live_51NWbThCScj5Xfnbx27IrkpKT1DGRcA9b7uHAkneTcwkX8pKIQpLu7FM5SVgL29AFg6yEYOvQgb1sMkKPnwAOkUCN00ASlmlDU8" />
                <stripe-buy-button buy-button-id="buy_btn_1NXTMICScj5Xfnbxbxp3at8F"
                    publishable-key="pk_live_51NWbThCScj5Xfnbx27IrkpKT1DGRcA9b7uHAkneTcwkX8pKIQpLu7FM5SVgL29AFg6yEYOvQgb1sMkKPnwAOkUCN00ASlmlDU8" />
                <!-- test -->
                <!-- <stripe-buy-button buy-button-id="buy_btn_1NXSusCScj5XfnbxUrtRwJtg"
                    publishable-key="pk_test_51NWbThCScj5XfnbxE3sYH1eUcwvJhYhHeLiwgoE7KJsKXW3P0bzTiUOl0OBRUCTuK1BHNSWxmkIHTg8fkE10BcIP00AQWoSFYp" />
                <stripe-buy-button buy-button-id="buy_btn_1NXSwmCScj5Xfnbxft6OZhqM"
                    publishable-key="pk_test_51NWbThCScj5XfnbxE3sYH1eUcwvJhYhHeLiwgoE7KJsKXW3P0bzTiUOl0OBRUCTuK1BHNSWxmkIHTg8fkE10BcIP00AQWoSFYp" />
                <stripe-buy-button buy-button-id="buy_btn_1NXT0PCScj5XfnbxwkvAIRzs"
                    publishable-key="pk_test_51NWbThCScj5XfnbxE3sYH1eUcwvJhYhHeLiwgoE7KJsKXW3P0bzTiUOl0OBRUCTuK1BHNSWxmkIHTg8fkE10BcIP00AQWoSFYp" />
                <stripe-buy-button buy-button-id="buy_btn_1NXT1cCScj5XfnbxyOSHWcYo"
                    publishable-key="pk_test_51NWbThCScj5XfnbxE3sYH1eUcwvJhYhHeLiwgoE7KJsKXW3P0bzTiUOl0OBRUCTuK1BHNSWxmkIHTg8fkE10BcIP00AQWoSFYp" />
                <stripe-buy-button buy-button-id="buy_btn_1NXT2KCScj5XfnbxP275x4eW"
                    publishable-key="pk_test_51NWbThCScj5XfnbxE3sYH1eUcwvJhYhHeLiwgoE7KJsKXW3P0bzTiUOl0OBRUCTuK1BHNSWxmkIHTg8fkE10BcIP00AQWoSFYp" /> -->
                <v-row justify="center" align="center">
                    <v-col>
                        <v-img style="text-align: center;" src="../../../public/img/qrcode101plus.png" width="120"
                            height="120" />
                        <div>
                            <span style="font-size: 16px; font-weight: 500; text-align: center;">Plus de 100
                                codes QR</span>
                        </div>
                        <v-btn class="white--text" color="#0074d4" href="mailto:paul.ceccaldi@vitissia.com">
                            Nous contacter
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>

export default {
    name: 'Boutique',
    data: () => ({
        valid: false,
        showResult: false,
        color: '',
        result: '',
        height: 300
    }),
    mounted() {

    },
    methods: {

    },
};
</script>
