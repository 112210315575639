var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "", tile: "" } },
    [
      _c(
        "v-card-title",
        { staticClass: "secondary white--text text-h5", attrs: { dark: "" } },
        [_vm._v(" Boutique ")]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: 30000, top: "", color: _vm.color, tile: "" },
          model: {
            value: _vm.showResult,
            callback: function ($$v) {
              _vm.showResult = $$v
            },
            expression: "showResult",
          },
        },
        [_vm._v(" " + _vm._s(_vm.result) + " ")]
      ),
      _c(
        "v-card-text",
        [
          _c(
            "v-container",
            [
              _c("stripe-buy-button", {
                attrs: {
                  "buy-button-id": "buy_btn_1NxQG0CScj5XfnbxaVRvNP1e",
                  "publishable-key":
                    "pk_live_51NWbThCScj5Xfnbx27IrkpKT1DGRcA9b7uHAkneTcwkX8pKIQpLu7FM5SVgL29AFg6yEYOvQgb1sMkKPnwAOkUCN00ASlmlDU8",
                },
              }),
              _c("stripe-buy-button", {
                attrs: {
                  "buy-button-id": "buy_btn_1NxQIqCScj5Xfnbx7b9gqLHJ",
                  "publishable-key":
                    "pk_live_51NWbThCScj5Xfnbx27IrkpKT1DGRcA9b7uHAkneTcwkX8pKIQpLu7FM5SVgL29AFg6yEYOvQgb1sMkKPnwAOkUCN00ASlmlDU8",
                },
              }),
              _c("stripe-buy-button", {
                attrs: {
                  "buy-button-id": "buy_btn_1NxQJRCScj5XfnbxBFHQNsVp",
                  "publishable-key":
                    "pk_live_51NWbThCScj5Xfnbx27IrkpKT1DGRcA9b7uHAkneTcwkX8pKIQpLu7FM5SVgL29AFg6yEYOvQgb1sMkKPnwAOkUCN00ASlmlDU8",
                },
              }),
              _c("stripe-buy-button", {
                attrs: {
                  "buy-button-id": "buy_btn_1NxQHLCScj5Xfnbx2ctxryCu",
                  "publishable-key":
                    "pk_live_51NWbThCScj5Xfnbx27IrkpKT1DGRcA9b7uHAkneTcwkX8pKIQpLu7FM5SVgL29AFg6yEYOvQgb1sMkKPnwAOkUCN00ASlmlDU8",
                },
              }),
              _c("stripe-buy-button", {
                attrs: {
                  "buy-button-id": "buy_btn_1NXTMICScj5Xfnbxbxp3at8F",
                  "publishable-key":
                    "pk_live_51NWbThCScj5Xfnbx27IrkpKT1DGRcA9b7uHAkneTcwkX8pKIQpLu7FM5SVgL29AFg6yEYOvQgb1sMkKPnwAOkUCN00ASlmlDU8",
                },
              }),
              _c(
                "v-row",
                { attrs: { justify: "center", align: "center" } },
                [
                  _c(
                    "v-col",
                    [
                      _c("v-img", {
                        staticStyle: { "text-align": "center" },
                        attrs: {
                          src: require("../../../public/img/qrcode101plus.png"),
                          width: "120",
                          height: "120",
                        },
                      }),
                      _c("div", [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-size": "16px",
                              "font-weight": "500",
                              "text-align": "center",
                            },
                          },
                          [_vm._v("Plus de 100 codes QR")]
                        ),
                      ]),
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text",
                          attrs: {
                            color: "#0074d4",
                            href: "mailto:paul.ceccaldi@vitissia.com",
                          },
                        },
                        [_vm._v(" Nous contacter ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }